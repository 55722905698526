import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://373ed127db64642b406ac9d5605f32fb@o1423748.ingest.us.sentry.io/4508167209877504",
  debug: false,
  environment: "production",
  release: "website@undefined",
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration()],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
});